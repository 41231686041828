'use client';

import { useEffect, useRef } from 'react';

import { AdSize } from '@/types/AdSizes';

interface AdSlotProps {
  path: string;
  size: AdSize[];
  id: string;
  targeting: { [key: string]: string | string[] };
  sizeMapping?: googletag.SizeMappingArray;
}

const useAdSlot = ({ path, size, id, targeting, sizeMapping }: AdSlotProps) => {
  const slotRef = useRef<googletag.Slot | null>(null);

  useEffect(() => {
    const googletag = (window as Window).googletag || {};
    googletag.cmd = googletag.cmd || [];

    const defineSlot = () => {
      googletag.cmd.push(() => {
        if (slotRef.current) {
          googletag.destroySlots([slotRef.current]);
        }

        slotRef.current = googletag
          .defineSlot(path, size, id)
          ?.addService(googletag.pubads());

        if (slotRef.current) {
          if (
            sizeMapping &&
            Array.isArray(sizeMapping) &&
            sizeMapping.length > 0
          ) {
            const mapping = googletag.sizeMapping();
            sizeMapping.forEach(([viewportSize, slotSize]) => {
              mapping.addSize(viewportSize, slotSize);
            });
            slotRef.current.defineSizeMapping(mapping.build());
          }

          Object.entries(targeting).forEach(([key, value]) => {
            slotRef.current?.setTargeting(key, value);
          });

          googletag.enableServices();

          googletag.display(id);
        }
      });
    };

    defineSlot();

    return () => {
      googletag.cmd.push(() => {
        googletag.destroySlots([slotRef.current]);
      });
    };
  }, [path, size, id, targeting, sizeMapping]);
};

export default useAdSlot;
